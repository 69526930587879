import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/fedEx"
  },
  /*{
    path: "/:pathMatch(.*)*",
    title: "404页面",
    name: "NotFound",
    component: () => import("@/views/error-page/404.vue"),
  },*/
  // 看板入口
  {
    path: '/fedEx',
    component: () => import('@/views/customized/fedEx/homeNew.vue'),
    name: 'FedExHome',
    meta: { title: 'FedExHome', },
    children: [],
  },
  // 看板菜单Iframe页
  {
    path: '/fedExHome',
    component: () => import('@/views/customized/fedEx/index.vue'),
    name: 'FedEx',
    meta: { title: 'FedEx', },
    children: [],
  },
  // 花都联邦实时看板
  {
    path: '/fedExHome/realTime',
    component: () => import('@/views/customized/fedEx/realTime.vue'),
    name: 'FedExRealTime',
    meta: { title: 'FedEx', },
    children: [],
  },
  // 花都联邦阶段看板1
  {
    path: '/fedExHome/period',
    component: () => import('@/views/customized/fedEx/period.vue'),
    name: 'FedExPeriod',
    meta: { title: 'FedEx', },
    children: [],
  },
  // 花都联邦阶段看板1
  {
    path: '/fedExHome/periodNext',
    component: () => import('@/views/customized/fedEx/periodNext.vue'),
    name: 'FedExPeriodNext',
    meta: { title: 'FedEx', },
    children: [],
  },
  //   出口仓库实时看板
  {
    path: "/exportWarehouse/realTime",
    name: 'ExportWarehouseRealTime',
    meta: { title: 'ExportWarehouseRealTime' },
    component: () => import("@/views/exportWarehouse/realTime/index.vue"),
  },
  //   出口仓库阶段报告
  {
    path: "/exportWarehouse/stage",
    name: 'ExportWarehouseStage',
    meta: { title: 'ExportWarehouseStage' },
    component: () => import("@/views/exportWarehouse/stage/index.vue"),
  },
  //   抽真空实时看板
  {
    path: "/vacuuming/realTime",
    name: 'VacuumingRealTime',
    meta: { title: 'VacuumingRealTime' },
    component: () => import("@/views/vacuuming/realTime/index.vue"),
  },
  //   抽真空阶段看板
  {
    path: "/vacuuming/stage",
    name: 'VacuumingStage',
    meta: { title: 'VacuumingStage' },
    component: () => import("@/views/vacuuming/stage/index.vue"),
  },
  //   自动线实时看板
  {
    path: "/automaticLine/realTime",
    name: 'AutomaticLineRealTime',
    meta: { title: 'AutomaticLineRealTime' },
    component: () => import("@/views/automaticLine/realTime/index.vue"),
  },
  //   自动线阶段看板
  {
    path: "/automaticLine/stage",
    name: 'AutomaticLineStage',
    meta: { title: 'AutomaticLineStage' },
    component: () => import("@/views/automaticLine/stage/index.vue"),
  },
  //   配货实时看板
  {
    path: "/distribution/realTime",
    name: 'DistributionRealTime',
    meta: { title: 'DistributionRealTime' },
    component: () => import("@/views/distribution/realTime/index.vue"),
  },
  //   配货阶段看板
  {
    path: "/distribution/stage",
    name: 'DistributionStage',
    meta: { title: 'DistributionStage' },
    component: () => import("@/views/distribution/stage/index.vue"),
  },
  //   环形线实时看板
  {
    path: "/loopLine/realTime",
    name: 'LoopLineRealTime',
    meta: { title: 'LoopLineRealTime' },
    component: () => import("@/views/loopLine/realTime/index.vue"),
  },
  //   环形线阶段看板
  {
    path: "/loopLine/stage",
    name: 'LoopLineStage',
    meta: { title: 'LoopLineStage' },
    component: () => import("@/views/loopLine/stage/index.vue"),
  },
]

const router = new VueRouter({
  routes
})

export default router
