/*英文*/
export default {
    common:{
        selectTime: "Set Default Time",
        confirm: "Confirm",
        cancel: "Cancel",
        startTime: "Start Time",
        endTime: "End Time",
        pleaseSelect: 'Please select',
    },
    home: {
        title: "OB eWarehouse Real-time Dashboard",
        inNum: "Package In (Pcs)",
        inNumRate: "Utilization%",
        outNum: "Package Out (Pcs)",
        outNumRate: "Package Out %",
        reportkNum: "Declaration Completed (Pcs)",
        reportkNumRate: "Declaration Completed%",
        checkNum: "Intercept (Pcs)",
        releaseNum: "Released for package out (Pcs)",
        outstockWeight: " Released for package out",
        zoneA: "Zone A",
        zoneB: "Zone B",
        zoneC: "Zone C",
        zoneD: "Zone D",
        zoneE: "Zone E",
        zoneF: "Zone F",
        notUse: "Unavailable",
        inLibrary: "Under Declaration",
        declarationCompleted: "Declaration Completed",
        applicationComplete: "Whole Pallet Declaration Completed",
        inspection: "Intercept",
        close: "close",
        groundStacking: "Grid Code",
        serialNumber: "Serial No.",
        mainOrderNumber: "MAWB",
        subOrderNumber: "AWB",
        customsStatus: "Declaration Status",
        release: "Released",
        exit: "Return",
        inStockStatus: "Package Storing",
        temporaryStorage: "Pending",
        outbound: "Caged out",
        notReceived: "Yet Cage In",
        cons: "Cons",
        declarationProgress: "Declaration Progress",
        weightInStock: "Inventory Weight(Kg)",
    },
    exportWarehouseStageReport: {
        title: "OB eWarehouse Periodic Report",
        inboundNum: "Package In (Pcs)",
        weight: "Package In (Kg)",
        instockNum: "Storing (Pcs)",
        instockWeight: "Storing (Kg)",
        releaseNum: "Released & Pending (Pcs)",
        inboundNumberAdnPieces: "Package In (Shpts/Pcs)",
        incomingWeight: "Package In (Kg)",
        declarationCompletionRate: "Declaration Completed %",
        inspectionRate: "Intercept %",
        abnormalDeclaration: "Declaration Exception",
        releasedPending: "Released & Pending (Pcs)",
        pcs: "Pcs",
        ratio: "Ratio",
        shpts: "Shpts",
    },
    vacuumBoard: {
        titleName: "ecZip Real-time Dashboard",
        deviceStatus: "Device Status",
        no1: "No.1",
        no2: "No.2",
        no3: "No.3",
        no4: "No.4",
        no5: "No.5",
        on: "On",
        outage: "Outage",
        off: "Off",
        operationEfficiency: "Operation Efficiency",
        bag_Hour: "Bag/Hour",
        periodicOutput: "Vacuuming Bag",
        lastingTime: "Lasting Time",
        average: "Average",
        h: "h",
        h2: "h",
        bags: "Bags",
    },
    vacuumPumpingStage: {
        titleName: "ecZip Periodic Report",
        periodicOutput: "Vacuuming Bag",
        operationEfficiency: "Operation Efficiency",
        average: "Average",
        averageValue: "Average",
        lastingTime: "Lasting Time",
        periodicOperationEfficiencyRanking: "Periodic Operation Efficiency Ranking",
        h: "Hour",
        bags: "Bags",
        weeklySummary: "Week",
        MonthlySummary: "Month",
        AnnualSummary: "Year",
    },
    automaticLineMonitoring: {
        titleName: "Linear Sort Real-time Dashboard",
        titleName2: "Loop Sort Real-time Dashboard",
        no: "No.",
        barcode: "Barcode",
        weight: "Weight",
        length: "Length",
        width: "Width",
        height: "Height",
        cubicVolume: "Dimension",
        time: "Time",
        Efficiency_pcs_h: "Efficiency(Pcs/h)",
        pcsAndH: 'Pcs/h',
        highest: "Highest",
        realTime: "Real-time",
        barcodesTotalNumber: "Total Number of Average",
        dataIntegrity: "Data Valid",
        dataError: "Data Invalid",
    },
    automaticLineStage: {
        titleName: "Linear Sort Periodic Report",
        titleName2: "Loop Sort Periodic Report",
        handledPcs: "Handled Pcs",
        handledWeight: "Handled Weight(Kg)",
        pakAverageWeight: "PAK Average Weight(Kg)",
        boxAverageWeight: "Box Average Weight(Kg)",
        netEfficiency: "Gross Efficiency (Pcs/h)",
        grossEfficiency: "Net Efficiency (Pcs/h)",
        barcodeReadRate: "Barcode Read Rate",
        dimWeightReadRate: "Dimension-weight Read Rate",
        sortingExceptionRate: "Sorting Exception Rate",
        averagePcs_Ba: "Average Pcs/Bag",
        dataExceptionNum: "Data Exception",
        linearSort: "Linear Sort",
        loopSort: "Loop Sort",
        manualSort: "Manual Sort",
        total: "Total",
        pcs: "Pcs",
        weight: "Weight",
        totalHandledPcs: "Total Handled Pcs",
        totalHandledWeight: "Total Handled Weight(KG)",
        averagePcsBag: "Average Pcs/Bag",
        averagePackageWeight: "Average Package Weight(KG)",
        truckNo: "Truck No.",
        truckCons: "Truck Cons",
        packageType: "Package Type",
        truckArrivalTime: "Truck Arrival Time",
        truckSortingStartTime: "Truck Sorting Start Time",
        truckSortingEndTime: "Truck Sorting End Time",
        dataException: "Data Exception",
        Recorded: "Recorded",
        noRecord: "No Record",
        ratio: "Ratio",
        weightCubicVolumeException: "Weight & Cubic Volume Exception",
        weighting: "Weight",
        cubicVolume: "Cubic Volume",
        sortingExceptionRatio: "Sorting Exception Ratio",
        systemDefault: "System Default",
        manualPause: "Manual Pause",
        timeout: "Timeout",
        Others: "Others",
        sort: "Sorting Line",
        weeklyReportExport: "Weekly Report",
        monthlyReportExport: "Monthly Report",
        annualizedReport: "Annualized Report",
        carUsageRate: "Trolley Usage Rate",
    },
    distributionRealTime: {
        title: 'eMatch Real-time Dashboard',
        allocationProgress: 'AllocationProgress',
        cancel: 'Cancel',
        truckLoadingProgress: 'Truck Loading Progress',
        tips: 'Click position for more info',
        allocationProgressDetails: 'Allocation Progress Details',
        piece: 'Pcs',
        totalNumberShipments: 'Total No. of Allocated Pieces',
        numberScannedArrivalItems: 'No. of Scanned Pieces',
        notScannedPieces: 'No. of Unscanned Pieces',
    },
    distributionStage: {
        title: 'eMatch Periodic Report',
        uldAllocationSummary: 'ULD Allocation Summary',
        allocationPerformance: 'Allocation Performance',
        allocationVolume: 'Allocation Volume',
        analysisOfUnallocated: 'Analysis of Unallocated',
        uld: 'ULD',
        airport: 'Airport',
        search: 'Search',
        shipments: 'Shipments',
        pieces: 'Pieces',
        weight: 'Weight(Kg)',
        noMawb: 'No MAWB',
        noAwb: 'No AWB',
        unairworthy: 'Embargo',
        noDestination: 'No Destination',
        noData: 'No Data',
        temporaryNoData: 'No Data',
        weightRevenue: 'Weight Revenue(KG)',
        noPieceCnt: 'No Piece Cnt',
        ticket: 'Shipments',
        quantityUnit: 'No. of ULD',
        mawbMissing: 'MAWB Missing',
        awbMissing: 'AWB Missing',
        noPieces: 'Piece Count Missing',
        splitShipment: 'Split Shipment',
        unalignedUrsaAmongMps: 'Unaligned URSA among MPS',
        noDestinationAirport: 'Shipment URSA excluded from URSA-Split mapping',
        databaseNoData: 'No Dim/Weight Data',
        unavailableData: 'Unqualified Dim/Weight Data',
        noUld: 'Shipment URSA without Matched ULD',
        uldSizeExceedingLimit: 'Package L/W/H Exceeds ULD Dimension',
    }
};
