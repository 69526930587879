/*中文*/
export default {
    common:{
        selectTime: "设置默认时间",
        confirm: "确定",
        cancel: "取消",
        startTime: "开始时间",
        endTime: "结束时间",
        pleaseSelect: '请选择',
    },
    home: {
        title: "出口仓库实时看板",
        inNum: "进仓件数",
        inNumRate: "在库率",
        outNum: "出仓件数",
        outNumRate: "出仓率",
        reportkNum: "完成申报件数",
        reportkNumRate: "完成申报率",
        checkNum: "查验件数",
        releaseNum: "放行待出库件数",
        outstockWeight: "放行待出库重量",
        zoneA: "A区",
        zoneB: "B区",
        zoneC: "C区",
        zoneD: "D区",
        zoneE: "E区",
        zoneF: "F区",
        notUse: "未使用",
        inLibrary: "待申报",
        declarationCompleted: "申报完成",
        applicationComplete: "整板申报完成",
        inspection: "查验",
        close: "关闭",
        groundStacking: "地堆码",
        serialNumber: "序号",
        mainOrderNumber: "主单号",
        subOrderNumber: "子单号",
        customsStatus: "海关状态",
        release: "放行",
        exit: "退场",
        inStockStatus: "在库状态",
        temporaryStorage: "暂存",
        outbound: "已出库",
        notReceived: "未入库",
        cons: "con码",
        declarationProgress: "申报进度",
        weightInStock: "在库重量",
    },
    exportWarehouseStageReport: {
        title: "出口仓库阶段报告",
        inboundNum: "入库件数",
        weight: "入库重量(KG)",
        instockNum: "在库件数",
        instockWeight: "在库重量(KG)",
        releaseNum: "放行滞留件数",
        inboundNumberAdnPieces: "进仓票数/件数",
        incomingWeight: "进仓重量",
        declarationCompletionRate: "申报完成率",
        inspectionRate: "查验率",
        abnormalDeclaration: "申报异常",
        releasedPending: "放行滞留件数",
        pcs: "件数",
        ratio: "比率",
        shpts: "票数",
    },
    vacuumBoard: {
        titleName: "抽真空实时看板",
        deviceStatus: "设备状态",
        no1: "1号",
        no2: "2号",
        no3: "3号",
        no4: "4号",
        no5: "5号",
        on: "正常",
        outage: "故障",
        off: "关机",
        operationEfficiency: "作业效率",
        bag_Hour: "袋/小时",
        periodicOutput: "阶段产量",
        lastingTime: "作业总时长",
        average: "平均值",
        h: "h",
        h2: "小时",
        bags: "袋",
    },
    vacuumPumpingStage: {
        titleName: "抽真空阶段看板",
        periodicOutput: "阶段产量",
        operationEfficiency: "作业效率",
        average: "平均",
        averageValue: "平均值",
        lastingTime: "作业总时长",
        periodicOperationEfficiencyRanking: "阶段作业效率排名",
        h: "小时",
        bags: "袋",
        weeklySummary: "周",
        MonthlySummary: "月",
        AnnualSummary: "年",
    },
    automaticLineMonitoring: {
        titleName: "自动线实时看板",
        titleName2: "环形线实时看板",
        no: "序号",
        barcode: "条码",
        weight: "重量",
        length: "长",
        width: "宽",
        height: "高",
        cubicVolume: "体积",
        time: "时间",
        Efficiency_pcs_h: "效率(件/小时)",
        pcsAndH: '件/小时',
        highest: "最高",
        realTime: "实时",
        barcodesTotalNumber: "包裹总数",
        dataIntegrity: "数据完整",
        dataError: "数据异常",
    },
    automaticLineStage: {
        titleName: "自动线阶段看板",
        titleName2: "环形线阶段看板",
        handledPcs: "过机件数",
        handledWeight: "过机重量(KG)",
        pakAverageWeight: "PAK平均重量(KG)",
        boxAverageWeight: "BOX平均重量(KG)",
        netEfficiency: "毛效率(Pcs/h)",
        grossEfficiency: "净效率(Pcs/h)",
        barcodeReadRate: "条码读取率",
        dimWeightReadRate: "重量/体积读取率",
        sortingExceptionRate: "Belt尾货占比",
        averagePcs_Ba: "平均每袋件数",
        dataExceptionNum: "异常数据件数",
        linearSort: "自动线",
        loopSort: "环形线",
        manualSort: "人工线",
        total: "总计",
        pcs: "件数",
        weight: "重量",
        totalHandledPcs: "分拣总件数",
        totalHandledWeight: "分拣总重量",
        averagePcsBag: "平均每袋件数",
        averagePackageWeight: "包裹平均重量",
        truckNo: "车牌号",
        truckCons: "车CONS号",
        packageType: "货物类型",
        truckArrivalTime: "到达时间",
        truckSortingStartTime: "开始分拣时间",
        truckSortingEndTime: "分拣结束时间",
        dataException: "异常数据",
        Recorded: "系统有数据",
        noRecord: "无记录",
        ratio: "百分比",
        weightCubicVolumeException: "重量/体积异常",
        weighting: "称重",
        cubicVolume: "体积",
        sortingExceptionRatio: "Belt货物占比",
        systemDefault: "满10",
        manualPause: "暂停",
        timeout: "超时",
        Others: "其他",
        sort: "分拣线",
        weeklyReportExport: "周报告",
        monthlyReportExport: "月报告",
        annualizedReport: "年报告",
        carUsageRate: "小车利用率",
    },
    distributionRealTime: {
        title: '配货实时看板',
        allocationProgress: '配货进度',
        cancel: '取消',
        truckLoadingProgress: '装车扫描进度',
        tips: '注：点击板型可查看进度',
        allocationProgressDetails: '配货进度明细',
        piece: '件',
        totalNumberShipments: '配货总件数',
        numberScannedArrivalItems: '已扫描到货件数',
        notScannedPieces: '未扫描到货件数',
    },
    distributionStage: {
        title: '配货阶段看板',
        uldAllocationSummary: '配置情况汇总',
        allocationPerformance: '配货表现',
        allocationVolume: '配货货量',
        analysisOfUnallocated: '未配货分析',
        uld: '板型',
        airport: '目的地机场',
        search: '搜索',
        shipments: '票数',
        pieces: '件数',
        weight: '重量(Kg)',
        noMawb: '无主单号',
        noAwb: '无子单号',
        unairworthy: '不适航',
        noDestination: '无目的机场',
        noData: '无数据',
        temporaryNoData: '暂无数据',
        weightRevenue: '重量收益(KG)',
        noPieceCnt: '无Piece Cnt',
        ticket: '票',
        quantityUnit: 'ULD数量：个',
        mawbMissing: '无主运单号',
        awbMissing: '无分运单号',
        noPieces: '无件数',
        splitShipment: '一票多件不齐',
        unalignedUrsaAmongMps: '子件URSA不一致',
        noDestinationAirport: '无目的地机场',
        databaseNoData: '数据库无数据',
        unavailableData: '数据库无效数据',
        noUld: '当天无匹配ULD',
        uldSizeExceedingLimit: '分运单ULD尺寸超限',
    }
};
